import React from 'react'

function OnHold() {
  return (
    <div className='theme-card h-100'>
      <h2 className='title text-center'>En attente</h2>
      <div className='text-center py-4'>
        <h3 className='title2'>Dating</h3>
        <p className='mb-0' style={styles.value}>0</p>
        <div className="w-100 line" />
      </div>
      <div className='text-center py-4'>
        <h3 className='title2'>Annuaire</h3>
        <p className='mb-0' style={styles.value}>0</p>
        <div className="w-100 line" />
      </div>
      <div className='text-center py-4'>
        <h3 className='title2'>Dating</h3>
        <p className='mb-0' style={styles.value}>0</p>
      </div>
    </div>
  )
}

export default OnHold

const styles = {
  value:{
    fontSize: 128,
    fontWeight: "bold",
  },
};
