import React from "react";
import { lastMonths } from "../../helpers/dates";
import ACD from "./Tendency/acd";
import Calls from "./Tendency/calls";
import Legend from "./Tendency/legend";

function Tendency() {
  const [dates, setDates] = React.useState([]);

  React.useEffect(() => {
    setDates(lastMonths());
  }, []);

  return (
    <div className="theme-card w-100" style={{ height: "100%" }}>
      <h2 className="title">Tendance sur les dernières mois</h2>
      <ACD dates={dates} />
      <Calls dates={dates} />
      <Legend />
    </div>
  );
}

export default Tendency;
