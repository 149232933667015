import React from 'react'
import PropTypes from 'prop-types'
import Nav from './nav';
import Alert from '../components/Alert';
import { useAlert } from '../../context/AlertContext';

import '../../scss/main.scss';

function Layout({ children, title, ...props }) {
  const { state } = useAlert();
  const { open } = state;

  return (
    <div className='screen-container' {...props}>
      <Alert />
      <Nav title={title} />
      <main className={ open?"blur-page": "" }>
      {
        children
      }
      </main>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node,
}