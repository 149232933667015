import React from "react";
import { fillArray, formatData } from "../../../helpers/tendency";
import { getSecond } from "../../../helpers/dates";
import {
  getTendencyAcdAnnuaire,
  getTendencyAcdDating,
  getTendencyAcdSos,
} from "../../../services/getServices";
import Erreur from "../erreur";
import Loading from "../Loading";
import LineChartCard from "../LineChartCard";

const ACD = ({ dates }) => {
  const [dating, setDating] = React.useState([]);
  const [annuaire, setAnnuaire] = React.useState([]);
  const [sos, setSos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const handleData = ({ service, setState }) =>
    service()
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          if (data && data.length > 0)
            setState(data.map((item) => item.avg_duration2));
        } else {
          setError(true);
        }
      })
      .catch(() => {
        // setError(true);
      });

  const handleDataDating = () =>
    handleData({ service: getTendencyAcdDating, setState: setDating });

  const handleDataAnnuaire = () =>
    handleData({ service: getTendencyAcdAnnuaire, setState: setAnnuaire });

  const handleDataSos = () =>
    handleData({ service: getTendencyAcdSos, setState: setSos });

  const format = data => fillArray(data.map((item) => getSecond(item))).reverse()

  const setData = React.useCallback(() => {
    return {
      dating: format(dating),
      annuaire: format(annuaire),
      sos: format(sos),
    };
  }, [dating, annuaire, sos]);

  React.useEffect(() => {
    handleDataDating();
    handleDataAnnuaire();
    handleDataSos();
  }, []);

  React.useEffect(() => {
    if (dating.length > 0 && annuaire.length > 0 && sos.length > 0) {
      setLoading(false);
    }
  }, [dating, annuaire, sos]);

  if (error) {
    return (
      <div className="py-5">
        <Erreur />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="py-5">
        <Loading />
      </div>
    );
  }

  return (
    <LineChartCard title={"ACD"} data={formatData(setData())} labels={dates} />
  );
};

export default ACD;
