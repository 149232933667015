import React from 'react'
import ProfileLight from './Agent/light';

function Quizz() {
  return (
    <div>
      <h2 className='title'>Quizz culture française</h2>
      <h3 style={styles.title}>Les français sont forts sur quel art ?</h3>
      <small>Ecriver la réponse dans le groupe Basecamp Culture FR</small>
      <div className='line my-3'></div>
      <h3 style={styles.title2}>Quiz hier</h3>
      <p><strong>Chaque année combien y a t-il d’éditions de la “Semaine de la mode à paris?</strong></p>
      <h3 style={styles.title2}>Réponse 3</h3>
      <div className='d-flex align-items-center' style={{ justifyContent: "space-between" }}>
        <small><strong>Gagnant:</strong></small>
        <ProfileLight image="https://i.pravatar.cc/301" nom="Malalanirina" />
        <small style={{ color: "#68FC05" }}><strong>Prix : Prime augmenter de  5%</strong></small>
      </div>
    </div>
  )
}

export default Quizz;

const styles = {
  title: {
    fontWeight: 600,
    fontSize: 28,
  },
  title2: {
    fontWeight: 700,
    fontSize: 20,
  },
  text: {
    fontWeight: 700,
    fontSize: 16,
  },
};