import React from "react";
import Spotlight from "../components/spotlight";
import PopularCulture from "../components/popularCulture";
import Weather from "../components/weather";
import Quizz from "../components/quizz";

function Informations() {
  return (
    <div className="position-relative d-flex px-4 pb-4 h-100 flex-gap">
      <div className="theme-card" style={{ width: "42%" }}>
        <Spotlight />
      </div>
      <div className="theme-card" style={{ width: "25%" }}>
        <PopularCulture />
      </div>
      <div className="d-flex flex-column flex-gap" style={{ width: "33%" }}>
        <div className="theme-card">
          <Weather />
        </div>
        <div className="theme-card h-100">
          <Quizz />
        </div>
      </div>
    </div>
  );
}

export default Informations;
