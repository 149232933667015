import React from "react";
import BestAgent from "../components/bestAgent";
import BestPerformance from "../components/bestPerformance";
import Stats from "../components/stats";
import { socket } from "../../services/socketServices";
import { bestAgent, lastAgent } from "../../helpers/acd";

function Agent() {
  const [dating, setDating] = React.useState([]);
  const [annuaire, setAnnuaire] = React.useState([]);
  const [sos, setSos] = React.useState([]);
  const [erreur, setErreur] = React.useState(false);

  const handleData = ({ url, setState }) =>
    socket.on(url, (result) => {
      if (result) setState(result);
    });

  const handleDataDating = () =>
    handleData({ url: "rankingDating", setState: setDating });

  const handleDataAnnuaire = () =>
    handleData({ url: "rankingAnnuaire", setState: setAnnuaire });

  const handleDataSos = () =>
    handleData({ url: "rankingSos", setState: setSos });

  React.useEffect(() => {
    handleDataDating();
    handleDataAnnuaire();
    handleDataSos();
  }, []);

  return (
    <div className="d-flex flex-gap px-4">
      <div style={{ width: "25%" }}>
        <BestAgent />
      </div>
      <div style={{ width: "15%" }}>
        <BestPerformance best={bestAgent(dating)} needHelp={lastAgent(dating)} title="Dating" />
      </div>
      <div style={{ width: "15%" }}>
        <BestPerformance best={bestAgent(annuaire)} needHelp={lastAgent(annuaire)} title="Annuaire" />
      </div>
      <div style={{ width: "15%" }}>
        <BestPerformance best={bestAgent(sos)} needHelp={lastAgent(sos)} title="SOS" />
      </div>
      <div style={{ width: "30%" }}>
        <Stats />
      </div>
    </div>
  );
}

export default Agent;
