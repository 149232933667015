import React from "react";
import PropTypes from "prop-types";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { chartConfig } from "./settings";
import { numberWithSpaces } from "../../../helpers/number";

function PieChartCard({ titre, value, offhook, lost, rightBar = false }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer,
  ]);

  return (
    <div className="px-5 position-relative">
      {rightBar ? <div style={styles.rightBar}></div> : <></>}
      <h6 className="text-center" style={styles.title}>
        {titre}
      </h6>
      <div className="w-100">
        <ReactEChartsCore
          echarts={echarts}
          option={chartConfig(value)}
          notMerge={true}
          lazyUpdate={true}
          onChartReady={() => {}}
          style={{ height: 260 }}
        />
      </div>
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div>
          <span style={styles.label}>Décrochés</span>
          <span className="d-block" style={styles.number_value}>
            {numberWithSpaces(offhook)}
          </span>
        </div>
        <div>
          <span style={styles.label}>Perdus</span>
          <span className="d-block" style={styles.number_value}>
            {numberWithSpaces(lost)}
          </span>
        </div>
      </div>
    </div>
  );
}

PieChartCard.propTypes = {
  titre: PropTypes.string,
  value: PropTypes.number,
  offhook: PropTypes.number,
  lost: PropTypes.number,
  rightBar: PropTypes.bool,
};

export default PieChartCard;

const styles = {
  number_value: {
    fontWeight: 700,
    fontSize: 40,
  },
  label: {
    fontSize: 17,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  rightBar: {
    position: "absolute",
    height: "50%",
    right: 0,
    top: "50%",
    transform: "translateY(-70%)",
    borderRight: "2px solid #0C292E",
  },
};
