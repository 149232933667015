function chartConfig(value){
  return {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: ['50%', '50%'],
        radius: '100%',
        min: 0,
        max: 100,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 40,
            color: [
              [0.3, '#D60000'],
              [0.45, '#DE5607'],
              [0.7, '#FFD000'],
              [1, '#1ECF02']
            ]
          }
        },
        anchor: {
            show: true,
            showAbove: true,
            size: 25,
            color: "grey",
            itemStyle: {
              borderColor: "#D4D4D4",
              borderWidth: 5,
            },
          },
          pointer: {
            itemStyle: {
              color: "white",
            },
          },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '10%'],
          fontSize: 20
        },
        detail: {
          fontSize: 32,
          offsetCenter: [0, '40%'],
          valueAnimation: true,
          formatter: function (value) {
            return value + '%';
          },
          color: 'inherit'
        },
        data: [
          {
            value: 85.52,
          }
        ]
      }
    ]
  };
}

export { chartConfig }