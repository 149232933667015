import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET_URL,{
  reconnectionDelayMax: 30000,
});

socket.on('connect_error', (err) => {
  console.log(`connect_error due to ${err.message}`);
});

export { socket };