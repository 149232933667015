import React from "react";
import Proptypes from "prop-types";

const Item = ({ title, message }) => (
  <div className="mb-4">
    <h2 style={styles.d_5}>
      <strong>{title}</strong>
    </h2>
    <p style={styles.d_6}>
      <strong>{message}</strong>
    </p>
  </div>
);

function Message({ dating, annuaire, sos }) {
  return (
    <div className="container" style={{ width: "70%" }}>
      {dating ? <Item title="Dating" message={dating} /> : <></>}
      {annuaire ? <Item title="Annuaire" message={annuaire} /> : <></>}
      {sos ? <Item title="SOS" message={sos} /> : <></>}
    </div>
  );
}

export default Message;

Message.prototype = {
  dating: Proptypes.string,
  annuaire: Proptypes.string,
  sos: Proptypes.string,
};

Item.prototype = {
  title: Proptypes.string,
  message: Proptypes.string,
};

const styles = {
  d_6: {
    fontSize: 48,
  },
  d_5: {
    fontSize: 64,
  },
};
