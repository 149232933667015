import React from "react";
import PropTypes from "prop-types";

function Best({ image, nom, dating, annuaire, sos, lostCalls }) {
  return (
    <div className="d-flex flex-column agent best-agent">
      <div className="w-100 d-flex flex-column align-items-center mb-4">
        <div className={`img-profile mb-4 best`}>
          <img src={image} alt="" />
        </div>
        <h3 style={styles.nom}>{nom}</h3>
      </div>
      <div className="d-flex">
        <div className="pe-4">
          <span style={styles.label}>Dating</span>
          <span style={styles.value}>{dating}</span>
        </div>
        <div className="pe-4">
          <span style={styles.label}>Annuaire</span>
          <span style={styles.value}>{annuaire}</span>
        </div>
        <div className="pe-4">
          <span style={styles.label}>SOS</span>
          <span style={styles.value}>{sos}</span>
        </div>
        <div>
          <span style={styles.label}>Ap. traités</span>
          <span style={styles.value} className="text-center">{lostCalls}</span>
        </div>
      </div>
    </div>
  );
}

export default Best;

Best.propTypes = {
  image: PropTypes.string,
  nom: PropTypes.string,
  dating: PropTypes.string,
  annuaire: PropTypes.string,
  sos: PropTypes.string,
  lostCalls: PropTypes.number,
};

const styles = {
  nom: {
    fontSize: 36,
  },
  label: {
    fontSize: 20,
    display: "block",
  },
  value: {
    fontSize: 24,
    display: "block",
  },
};
