import React from "react";
import PropTypes from "prop-types";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { chartConfig } from "./settings";

function LightPieChart({ titre, value }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer,
  ]);

  return (
    <div className="px-0 position-relative">
      <h6 className="text-center" style={styles.title}>
        {titre}
      </h6>
      <div className="w-100 d-flex justify-content-center">
        <div style={{ width: "90%" }}>
          <ReactEChartsCore
            echarts={echarts}
            option={chartConfig(value, {
              height: 150,
              radius: ["72%", "90%"],
              fontSize: 16,
            })}
            notMerge={true}
            lazyUpdate={true}
            onChartReady={() => {}}
            style={{ height: 140 }}
          />
        </div>
      </div>
    </div>
  );
}

LightPieChart.propTypes = {
  titre: PropTypes.string,
  value: PropTypes.number,
};

export default LightPieChart;

const styles = {
  title: {
    fontWeight: 400,
    fontSize: 16,
  },
};
