import { ACD_DATAS } from "../constants/acd";
import { formatDate, lastMonths } from "./dates";

const formatData = (data, acd = ACD_DATAS) => {
  let res = [];
  for (const acd_key in data) {
    if (Object.hasOwnProperty.call(data, acd_key)) {
      const datas = data[acd_key];
      res.push({
        ...acd[acd_key],
        data: datas,
      });
    }
  }

  return res;
};

const filterCallData = (datas) => {
  const dates = lastMonths();
  const d = [];
  for (const date of dates) {
    const data = datas.find(item => date === formatDate(item.date));
    if(data) d.push(parseInt(data.number_of_call));
    else d.push(null);
  }

  return d;
}

const fillArray = (tab) => {
  let arr = new Array(6).fill(null);
  for (let i = 0; i < tab.length; i++) {
    arr[i] = tab[i];
  }
  return arr;
}

export { formatData, filterCallData, fillArray };
