import React from "react";

function Acd({ data }) {
  return (
    <>
      {data.map(({ time, rate, amount, type }, index) => (
        <p style={styles.d_6} key={index}>
          <strong>
            ACD {type} est dans le rouge {time}
          </strong>
          <br />
          <span>
            {rate}% d’appel perdus soit {amount}
          </span>
        </p>
      ))}
    </>
  );
}

export default Acd;

const styles = {
  d_6: {
    fontSize: 48,
  },
  d_5: {
    fontSize: 64,
  },
};
