import { ACD_DATAS } from "../../../constants/acd";
import { numberWithSpaces } from "../../../helpers/number";

const labelOption = (color = "#fff") => ({
  show: true,
  position: "top",
  rotate: 0,
  fontSize: 12,
  color,
  textBorderColor: "#fff0",
  rich: {
    name: {},
  },
  formatter: (value) => {
    return numberWithSpaces(value.data);
  },
});

const dataSetting = ({ color, name, data }) => ({
  name,
  type: "bar",
  color,
  barGap: 0.5,
  barWidth: 10,
  label: labelOption(),
  emphasis: {
    focus: "series",
  },
  data: data,
});

function chartConfig(title, labels, data) {
  return {
    title: {
      text: title,
      textStyle: {
        color: "#fff",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      show: false,
      data: Object.keys(ACD_DATAS).map((key) => ACD_DATAS[key].name),
    },
    toolbox: {
      show: false,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: labels,
        axisLabel: {
          color: "white",
          fontSize: 12,
          fontWeight: 600,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: "white",
          fontSize: 12,
          fontWeight: 600,
          formatter: (value) => {
            return numberWithSpaces(value);
          },
        },
        splitNumber: 3,
      },
    ],
    series: data.map((item) => dataSetting(item)),
  };
}

export { chartConfig };
