import React from "react";
import PropTypes from "prop-types";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { chartConfig } from "./settings";

import "../../../scss/charts.scss";

function GaugeChartCard({ title, value, time, objective }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
  ]);

  return (
    <div className="theme-card chart-card d-flex flex-column align-items-center">
      <h2 className="title">{title}</h2>
      <div className="w-100">
        <ReactEChartsCore
          echarts={echarts}
          option={chartConfig(value)}
          notMerge={true}
          lazyUpdate={true}
          onChartReady={() => {}}
        />
      </div>
      <div className="data text-center">
        <span className="d-block time">{time}</span>
        <span className="d-block objective">
          <small className="d-inline-block me-3">Objectif</small>
          <span>{objective}</span>
        </span>
      </div>
    </div>
  );
}

GaugeChartCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  time: PropTypes.string,
  objective: PropTypes.string,
};

export default GaugeChartCard;
