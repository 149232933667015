import React from "react";
import Acd from "../jsx/components/Alert/acd";
import Appels from "../jsx/components/Alert/appels";
import Pause from "../jsx/components/Alert/pause";
import Message from "../jsx/components/Alert/message";

export const AlertContext = React.createContext();

const closeAlertTime = 5000;

const defaultState = {
  open: false,
  children: <></>,
  icon: "danger",
  title: "Alerte infos",
  variant: "danger",
};

function reducer(state, actions) {
  const { type, component, action, ...props } = actions;
  let children = null,
    t = {};
  const open = action === "open";
  if (component) {
    switch (component) {
      case "acd":
        children = <Acd {...props} />;
        break;
      case "appels":
        children = <Appels {...props} />;
        break;
      case "pause":
        children = <Pause {...props} />;
        break;
      case "message":
        children = <Message {...props} />;
        break;
      default:
        return state;
    }
  }

  if (type) {
    t =
      type === "alert"
        ? {
            icon: "danger",
            title: "Alerte infos",
            variant: "danger",
          }
        : {
            icon: "info",
            title: "Infos DU JOURS",
            variant: "success",
          };
  }

  return { ...state, open, children, ...t };
}

const AlertContextProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  const close = () => {
    dispatch({ action: "close" });
  };

  React.useEffect(() => {
    let h = null;
    if (state.open) {
      h = setTimeout(() => {
        close();
      }, closeAlertTime);
    }

    return () => {
      h && clearTimeout(h);
    }
  }, [state]);

  return (
    <AlertContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => React.useContext(AlertContext);

export default AlertContextProvider;
