import React from "react";
import Time from "./Agent/time";
import { acd_ojective } from "../../constants/acd";
import { Col, Row } from "react-bootstrap";
import { socket } from "../../services/socketServices";
import {
  formatAgents,
  getAnnuaire,
  getDating,
  getSos,
} from "../../helpers/acd";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper";

const ProgressSection = ({ title, data, objective }) => {
  const lists = formatAgents(data, 8);

  const formatService = (service) => {
    if (service) {
      let t = service.replace(/DATING.|ANNUAIRE.|SOS./gi, "")?.split(":");
      if(t && t.length){
        return t[0];
      }
    }
    return "";
  }

  return (
    <div className="py-3">
      <h3 className="mb-3 title2">{title}</h3>
      <Swiper
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        className="swiper-agent"
        modules={[Autoplay]}
      >
        {lists.map((list, index) => (
          <SwiperSlide key={index}>
            <Row>
              {list.map((item, index) => {
                const name = item.name ? item.name.split(" ")[0] : "";
                return (
                  <Col sm={3} key={index}>
                    <Time
                      key={index}
                      id={formatService(item.service)}
                      image={item.photos}
                      nom={name}
                      max={objective}
                      time={item.start_time}
                    />
                  </Col>
                );
              })}
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
      {lists.length ? (
        <></>
      ) : (
        <div className="">
          Aucun appel
        </div>
      )}
    </div>
  );
};

function Progress() {
  const [dating, setDating] = React.useState([]);
  const [annuaire, setAnnuaire] = React.useState([]);
  const [sos, setSos] = React.useState([]);
  const [erreur, setErreur] = React.useState(false);

  const handleData = () =>
    socket.on("on_calls", (result) => {
      setDating(getDating(result));
      setAnnuaire(getAnnuaire(result));
      setSos(getSos(result));
    });

  React.useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="theme-card h-100">
      <h2 className="title">En cours</h2>
      <ProgressSection
        title="Service Dating"
        data={dating}
        objective={acd_ojective.dating}
      />
      <div className="w-100 line" />
      <ProgressSection
        title="Service Annuaire"
        data={annuaire}
        objective={acd_ojective.annuaire}
      />
      <div className="w-100 line" />
      <ProgressSection
        title="Service SOS"
        data={sos}
        objective={acd_ojective.sos}
      />
    </div>
  );
}

export default Progress;
