import axios from "axios";
import axiosRetry from "axios-retry";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  config.params = config.params || {};
  return config;
});

axiosRetry(axiosInstance, {
  retries: 15,
  retryCondition: (error) => {
    return error.response.status === 503;
  },
});

export default axiosInstance;
