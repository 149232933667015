import React from "react";
import Performance from "./Agent/performance";
import { formatTimeData } from "../../helpers/dates";

function BestPerformance({ title, needHelp, best }) {
  return (
    <div className="theme-card px-4 h-100">
      <h2 className="text-center mb-3 title">{title}</h2>
      <h3 className="text-center mb-4 title2">Meilleur performance</h3>

      <div>
        {best.map((item, index) => (
          <Performance
            image={process.env.REACT_APP_AGENT_IMAGE_URL + item.profile}
            acd={formatTimeData(item.avg_duration2)}
            appels={item.number_of_calls}
            hasRibbon
            nom={item.firstname.split(" ")[0]}
            key={index}
          />
        ))}
      </div>
      <h3 className="text-center mt-4 mb-4 title2">Besoin d’aide</h3>

      <div>
        {needHelp.map((item, index) => {
          return (
            <Performance
              image={process.env.REACT_APP_AGENT_IMAGE_URL + item.profile}
              acd={formatTimeData(item.avg_duration2)}
              redTime
              appels={item.number_of_calls}
              nom={item.firstname.split(" ")[0]}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default BestPerformance;
