import React from "react";
import Informations from "../screens/informations";
import ScreenLayout from "../layouts/screen";

const Titles = ["Culturelle France"];

function Culture() {
  return <ScreenLayout screens={[<Informations />]} titles={Titles} />;
}

export default Culture;
