import axios from "axios";
import axiosInstance from "./AxiosInstance";

export function getWeather() {
  return axios.get(`https://api.openweathermap.org/data/2.5/weather`, {
    params: {
      lat: 48.864716,
      lon: 2.349014,
      appid: process.env.REACT_APP_OPENWEATHER_API_KEY,
      lang: "fr",
    },
  });
}

export function getNews(q = "France", size = 1) {
  return axios.get(`https://newsdata.io/api/1/news`, {
    params: {
      q,
      language: "fr",
      countries: "FR",
      page_size: size
    },
    headers: {
      "x-api-key": process.env.REACT_APP_NEWSAPI_API_KEY,
    }
  });
}

export function getTendencyAcdDating() {
  return axiosInstance.get(`sixmounthacddating`);
}

export function getTendencyAcdAnnuaire() {
  return axiosInstance.get(`sixmounthacdannuaire`);
}

export function getTendencyAcdSos() {
  return axiosInstance.get(`sixmounthacdsos`);
}

export function getTendencyCallDating() {
  return axiosInstance.get(`sixmounthnumberofcalldating`);
}

export function getTendencyCallAnnuaire() {
  return axiosInstance.get(`sixmounthnumberofcallannuaire`);
}

export function getTendencyCallSos() {
  return axiosInstance.get(`sixmounthnumberofcallsos`);
}

export function getBestAgent() {
  return axiosInstance.get(`bestagentcurentmounth`);
}

export function getBestAgentInfos() {
  return axiosInstance.get(`bestagentsinfos`);
}

export function responseWait(){
  return axiosInstance.get("longestwait");
}

export function getHighestPrime(){
  return axiosInstance.get("highestprime");
}

export function getAcdHighestPrime(){
  return axiosInstance.get("acdhighestprime");
}