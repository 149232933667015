import React from "react";
import { Col, Row } from "react-bootstrap";
import { getNews } from "../../services/getServices";
import Loading from "./Loading";
import { maxLength } from "../../helpers/texts";
import { formatLDate } from "../../helpers/dates";

const Movies = () => {
  const [movies, setMovies] = React.useState([]);
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    setloading(true);
    getNews("Film", 2).then(({ data }) => {
      if (data && data.articles) {
        setMovies(
          data.articles.map((article) => ({
            titre: article.title,
            image: article.urlToImage,
          }))
        );
      }
      setloading(false);
    });
  }, []);

  return (
    <div>
      <h3 style={styles.title} className="mb-3">
        Dernière film
      </h3>
      <Row>
        {!loading ? (
          movies.map((movie) => (
            <Col sm={6}>
              <img
                src={movie.image}
                alt=""
                style={styles.movie.poster}
                className="mb-4"
              />
              <p
                className="mb-0"
                style={styles.movie.title}
                title={movie.titre}
              >
                {maxLength(movie.titre)}
              </p>
            </Col>
          ))
        ) : (
          <Loading />
        )}
      </Row>
    </div>
  );
};

const Evenement = () => {
  const [event, setEvent] = React.useState(null);
  const [loading, setloading] = React.useState(true);

  React.useEffect(() => {
    setloading(true);
    getNews("Event", 1).then(({ data }) => {
      if (data && data.articles && data.articles.length) {
        setEvent(data.articles[0]);
      }
      setloading(false);
    });
  }, []);

  return (
    <div>
      <h3 style={styles.title} className="mb-3">
        Evènement
      </h3>
      {!loading ? (
        <>
          <h4 style={styles.event.date}>{formatLDate(event.publishedAt)}</h4>
          <h5 style={styles.event.title}>{maxLength(event.title, 18)}</h5>
          <img
            src={event.urlToImage}
            alt=""
            style={styles.movie.poster}
            className="mb-3"
          />
          <p className="mb-0" style={styles.event.text}>
            {maxLength(event.description, 90)}
          </p>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

function PopularCulture() {
  return (
    <div>
      <h2 className="title mb-3">Culture populaire</h2>
      <Movies />
      <div className="line my-3"></div>
      <Evenement />
    </div>
  );
}

export default PopularCulture;

const imageStyle = {
  width: "100%",
  objectFit: "cover",
  objectPosition: "center",
};

const styles = {
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  movie: {
    poster: {
      ...imageStyle,
      height: 250,
    },
    title: {
      fontWeight: 700,
      fontSize: 14,
    },
  },
  event: {
    date: {
      fontWeight: 600,
      fontSize: 20,
    },
    title: {
      fontWeight: 700,
      fontSize: 36,
    },
    image: {
      ...imageStyle,
      height: 200,
    },
    text: {
      fontWeight: 700,
      fontSize: 15,
    },
  },
};
