import React from 'react'
import { Col, Row } from 'react-bootstrap';
import LightLineChart from '../LineChartCard/light';
import { lastMonths } from '../../../helpers/dates';
import { formatData } from '../../../helpers/tendency';
import { ACD_DATAS_2 } from '../../../constants/acd';
import {
  getTendencyCallDating,
  getTendencyCallAnnuaire,
  getTendencyCallSos
} from "../../../services/getServices";

const defaultDataState = {
  dating: [],
  annuaire: [],
  sos: [],
};

export const Legend = () => (
  <Row className="mt-3">
    {Object.keys(ACD_DATAS_2).map((key, i) => (
      <Col md={4} key={i}>
        <div className="d-flex align-items-center">
          <span
            className="bar-line"
            style={{ backgroundColor: ACD_DATAS_2[key].color }}
          ></span>
          <span>{ACD_DATAS_2[key].name}</span>
        </div>
      </Col>
    ))}
  </Row>
);

const ACD = () => {
  const [dates, setDates] = React.useState([]);
  const [data, setData] = React.useState(defaultDataState);

  React.useEffect(() => {
    setDates(lastMonths(6, true));
    getTendencyCallDating().then(res1 => {
      let dtn = res1.data.filter(item => item.status==="Answered").map(item=>parseInt(item.number_of_call));
      getTendencyCallAnnuaire().then(res2=>{
        let ann = res2.data.filter(item => item.status==="Answered").map(item=>parseInt(item.number_of_call));
        getTendencyCallSos().then(res3 =>{
          let ss = res3.data.filter(item => item.status==="Answered").map(item=>parseInt(item.number_of_call));
          dtn.reverse();
          ann.reverse();
          ss.reverse();
          while (dtn.length < 6) dtn = [0, ...dtn]
          while (ann.length < 6) ann = [0, ...ann]
          while (ss.length < 6) ss = [0, ...ss]
          setData({
            dating: dtn,
            sos: ss,
            annuaire: ann,
          })
        })
      })
    })
    ;
  }, []);

  return <LightLineChart title={""} data={formatData(data, ACD_DATAS_2)} labels={dates} />;
};

export default ACD;
