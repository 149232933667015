import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ACD_DATAS } from '../../../constants/acd';

const Legend = () => (
  <Row className="mt-3 ps-5 pe-1">
    {Object.keys(ACD_DATAS).map((key, i) => (
      <Col md={4} key={i}>
        <div className="d-flex align-items-center">
          <span
            className="bar-line"
            style={{ backgroundColor: ACD_DATAS[key].color }}
          ></span>
          <span>{ACD_DATAS[key].name}</span>
        </div>
      </Col>
    ))}
  </Row>
);

export default Legend;