import React from "react";
import PropTypes from "prop-types";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { chartConfig } from "./semiSettings";

import "../../../scss/charts.scss";

function SemiGaugeChart({ title, value }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
  ]);

  return (
    <div className="w-100" style={{ height: 200, overflowY: "hidden" }}>
      <ReactEChartsCore
        echarts={echarts}
        option={chartConfig(value)}
        notMerge={true}
        lazyUpdate={true}
        onChartReady={() => {}}
        style={{ height: 250 }}
      />
    </div>
  );
}

SemiGaugeChart.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
};

export default SemiGaugeChart;
