import React from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "./Loading";
import { getNews } from "../../services/getServices";
import { maxLength } from "../../helpers/texts";

const Subject = ({ title }) => {
  const [state, setState] = React.useState(null);
  const [loading, setloading] = React.useState(true);

  React.useEffect(() => {
    setloading(true);
    getNews(title, 1, "rank").then(({ data }) => {
      if (data && data.articles && data.articles.length) {
        setState(data.articles[0]);
      }
      setloading(false);
    });
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="d-flex flex-column h-100">
      <h3 style={styles.title} className="mb-3">
        {title}
      </h3>
      <img
        src={state.media}
        alt=""
        style={styles.image}
        className="mb-3"
      />
      <p className="mb-auto" style={styles.text}>
        {maxLength(state.summary, 350)}
      </p>
      <p className="mb-0" style={styles.source}>
        Source : {state.rights}
      </p>
    </div>
  );
};

function Spotlight() {
  return (
    <div className="d-flex flex-column h-100">
      <h2 className="title">Infos à la Une</h2>
      <Row className="h-100">
        <Col sm={6} className="h-100">
          <Subject title={"France"} />
        </Col>
        <Col sm={6} className="h-100">
          {/* <Subject title={"Monde"} /> */}
        </Col>
      </Row>
    </div>
  );
}

export default Spotlight;

const styles = {
  title: {
    fontWeight: 700,
    fontSize: 32,
  },
  image: {
    width: "100%",
    height: 230,
    objectFit: "cover",
    objectPosition: "center",
  },
  text: {
    fontSize: 24,
  },
  source: {
    fontWeight: 600,
    fontSize: 15,
    textAlign: "right",
    color: "#FFC738",
  },
};
