import React from "react";
import { Col, Row } from "react-bootstrap";
import Appels from "../components/appels";
import Acd from "../components/acd";
import Tendency from "../components/tendency";

function PerformanceCallCenter() {
  return (
    <div
      className="position-relative d-flex px-4 pb-4"
      style={{ height: "100%" }}
    >
      <Row className="w-100 mx-0">
        <Col md={"7"} className="d-flex flex-column" style={{ padding: "0 6px 0 0" }}>
          <Acd />
          <Appels />
        </Col>
        <Col md={"5"} style={{ padding: "0 0 0 6px" }}>
          <Tendency />
        </Col>
      </Row>
    </div>
  );
}

export default PerformanceCallCenter;
