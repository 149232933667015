export const acd_ojective = {
  dating: "12:00",
  annuaire: "07:00",
  sos: "12:00",
};

export const ACD_DATAS = {
  dating: {
    name: "Dating",
    color: "#2382EC",
  },
  annuaire: {
    name: "Annuaire",
    color: "#17A959",
  },
  sos: {
    name: "SOS",
    color: "#AA0DCF",
  },
};

export const ACD_DATAS_2 = {
  dating: {
    name: "Dating",
    color: "#0E9CFF",
  },
  annuaire: {
    name: "Annuaire",
    color: "#8F40F9",
  },
  sos: {
    name: "SOS",
    color: "#F72081",
  },
};