import React from "react";
import Status from "../Agent/status";

function Appels({ dating, annuaire, sos, users }) {
  return (
    <div className="container" style={{ width: "70%" }}>
      <h2 style={styles.title}>
        <strong>Appels en attente</strong>
      </h2>
      <div className="d-flex">
        {
          dating? <div className="me-5">
          <span style={styles.label} className="me-3">
            Dating
          </span>{" "}
          <strong style={styles.title}>{dating}</strong>
        </div> : <></>
        }
        
        {
          annuaire? <div>
          <span style={styles.label} className="me-3">
            Annuaire
          </span>{" "}
          <strong style={styles.title}>{annuaire}</strong>
        </div> : <></>
        }
        {
          sos? <div className="me-5">
          <span style={styles.label} className="me-3">
            SOS
          </span>{" "}
          <strong style={styles.title}>{sos}</strong>
        </div> : <></>
        }
      </div>
      <div className="d-flex flex-wrap mb-5">
        {users.map((user, index) => (
          <div key={index} className="me-5">
            <Status
              nom={user.nom}
              active={user.active}
              image={user.image}
              imageSize={80}
              fontSize={24}
            />
          </div>
        ))}
      </div>
      <p style={styles.title2}>
        <strong>Veuillez prendre ses appels SVP</strong>
      </p>
    </div>
  );
}

export default Appels;

const styles = {
  title: {
    fontSize: 94,
  },
  label: {
    fontSize: 64,
  },
  title2: {
    fontSize: 36,
  },
};
