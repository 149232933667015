import React from "react";
import Performance from "../screens/callcenter";
import Appels from "../screens/appels";

import ScreenLayout from "../layouts/screen";

const Titles = ["Indicateur de Performance du Call Center", "Appels"];

function Kpi() {
  return (
    <ScreenLayout screens={[<Performance />, <Appels />]} titles={Titles} />
  );
}

export default Kpi;
