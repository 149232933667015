import React from "react";
import moment from "moment-timezone";
import "moment/locale/fr";
import { getWeather } from "../../services/getServices";
import Loading from "./Loading";

function Weather() {
  const [time, setTime] = React.useState("");
  const [weather, setWeather] = React.useState(null);

  React.useEffect(() => {
    const getWeatherTime = 1000 * 60 * 30;
    const handleTime = setInterval(() => {
      setTime(moment().tz("Europe/Paris").format("HH:mm"));
    }, 1000);

    getWeather().then(({ data }) => {
      setWeather(data);
    });

    const handleWeather = setInterval(() => {
      getWeather().then(({ data }) => {
        setWeather(data);
      });
    }, getWeatherTime);

    return () => {
      clearInterval(handleTime);
      clearInterval(handleWeather);
    };
  }, []);

  return (
    <div>
      <h2 className="title">Météo</h2>
      {weather && time ? (
        <div>
          <div className="d-flex w-100 align-items-center">
            <span style={styles.label} className="me-auto">
              Heure France{" "}
            </span>
            <span style={styles.value}>{time}</span>
          </div>
          <div className="line my-3"></div>
          <div className="d-flex w-100 align-items-center">
            <span
              style={{ ...styles.label, ...styles.capitalize }}
              className="me-auto"
            >
              {weather.weather.length > 0 ? weather.weather[0].description : ""}
            </span>
            <span>
              <span style={styles.label} className="me-2">
                Température
              </span>
              <span className="title">
                {`${(weather.main.temp_min - 273.15).toFixed(1)}°/${(
                  weather.main.temp_max - 273.15
                ).toFixed(1)}° C`}
              </span>
            </span>
          </div>
        </div>
      ) : (
        <Loading />
      )}

      <div className="line my-2"></div>
      <div style={styles.text}>
        <p>
          <span>Après-midi</span>
          <br />
          <span>
            Le soleil domine avec une bise froide. Des nuages circulent aussi de
            l'Alsace à Rhône-Alpes. Le pourtour méditerranéen conserve un temps
            plus menaçant.
          </span>
        </p>
        <p>
          <span>Ce soir</span>
          <br />
          <span>
            Le temps est calme et rapidement froid avec la multiplication rapide
            des gelées dans l'est.
          </span>
        </p>
        <p>
          <span>Cette nuit</span>
          <br />
          <span>
            Les gelées sont marquées au nord. Du sud des Alpes aux Cévennes
            jusqu'au Languedoc, une dégradation est attendue avec un risque de
            neige jusqu'à très basse altitude.
          </span>
        </p>
      </div>
      <p className="mb-0" style={styles.source}>
        Source : lachainemeteo.com
      </p>
    </div>
  );
}

export default Weather;

const styles = {
  label: {
    fontWeight: 700,
    fontSize: 20,
  },
  value: {
    fontWeight: 700,
    fontSize: 48,
  },
  text: {
    fontWeight: 700,
    fontSize: 15,
  },
  source: {
    fontWeight: 600,
    fontSize: 15,
    textAlign: "right",
    color: "#FFC738",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};
