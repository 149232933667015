import React from "react";
import PropTypes from "prop-types";

import "../../../scss/agent.scss";

const defaultImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

function Status({ image, nom, active, imageSize, fontSize }) {
  return (
    <div
      className={`agent d-flex flex-column align-items-center flex-gap my-4${
        active ? " active" : ""
      }`}
    >
      <div
        className="img-profile status"
        style={{ width: imageSize ?? 60, height: imageSize ?? 60 }}
      >
        <img
          src={
            image === "https://3cx.ucorp.io/user_images/" ||
            image === "https://3cx.ucorp.io/user_images/null"
              ? defaultImage
              : image
          }
          alt=""
        />
      </div>
      <strong
        className="d-block text-center"
        style={{ fontSize: fontSize ?? 16 }}
      >
        {nom}
      </strong>
    </div>
  );
}

Status.propTypes = {
  image: PropTypes.string,
  nom: PropTypes.string,
  active: PropTypes.bool,
  fontSize: PropTypes.number,
  imageSize: PropTypes.number,
};

export default Status;
