import { ACD_DATAS } from "../../../constants/acd";
import { formatMinute } from "../../../helpers/dates";

const labelStyle = {
  show: true,
  position: "top",
  color: "#fff",
  textBorderColor: "#fff0",
  formatter: ({data}) => formatMinute(data),
}

const anchorStyle = (color = "white") => ({
  color: color,
  borderWidth: 2,
});

const anchorSize = 8;

const dataSetting = ({ color, name, data }) => ({
  name: name,
  type: "line",
  areaStyle: {
    color: `${color}20`,
  },
  lineStyle: {
    color: color,
  },
  label: labelStyle,
  emphasis: {
    focus: "series",
  },
  symbolSize: anchorSize,
  itemStyle: anchorStyle(color),
  data: data,
});

function chartConfig(title, labels, data) {
  return {
    title: {
      text: title,
      textStyle:{
        color: "#fff",
      }
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      show: false,
      data: Object.keys(ACD_DATAS).map((key) => ACD_DATAS[key].name),
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel:{
          color: "white",
          fontSize: 12,
          fontWeight: 600,
        },
        data: labels,
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: {
          show: false,
        },
        max: function (value) {
          return value.max + 360;
        },
        axisLabel:{
          fontSize: 11,
          fontWeight: 600,
          color: "white",
          formatter: (value) => {
            return Math.round(value / 60)
          }
        },
        splitNumber:3,
      },
    ],
    series: data.map(item => dataSetting(item)),
  };
}

export { chartConfig, dataSetting };
