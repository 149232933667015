import React from "react";
import Agent from "../screens/agent";
import ScreenLayout from "../layouts/screen";

import "../../scss/performance.scss";

const Titles = ["Stats Performance Agent"];

function Performance() {
  return (
    <ScreenLayout
      screens={[<Agent />]}
      titles={Titles}
      className="performance-page"
    />
  );
}

export default Performance;
