import React from "react";
import PropTypes from "prop-types";

import "../../../scss/agent.scss";
import {
  addSecond,
  formatTimeData,
  getRate,
  getTimeDifference,
} from "../../../helpers/dates";
import { detailColor } from "../../../helpers/acd";
import { twoDigit } from "../../../helpers/number";

const defaultImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

function Time({ image, time, max, id, nom }) {
  const [currentTime, setCurrentTime] = React.useState(time);
  const t = getTimeDifference(time);
  const t_format = `${twoDigit(t.minutes)}:${twoDigit(t.seconds)}`;
  const rate = getRate(formatTimeData(t_format), max);

  React.useEffect(() => {
    setCurrentTime(t_format);
    const handleTime = setInterval(() => {
      setCurrentTime((prevTime) => {
        return addSecond(prevTime);
      });
    }, 1000);

    return () => clearInterval(handleTime);
  }, [time]);

  return (
    <div className="d-flex mb-3">
      <div
        className="pie animate no-round"
        style={{ "--p": rate, "--c": detailColor(rate) }}
      >
        <img
          src={
            image === "https://3cx.ucorp.io/user_images/" ||
            image === "https://3cx.ucorp.io/user_images/null"
              ? defaultImage
              : image
          }
        />
      </div>
      <div className="ms-2">
        <span className="text-lg">
          <strong>{nom}</strong>
        </span>
        <br />
        {id ? (
          <>
            <small className="text-lighter">{id}</small>
            <br />
          </>
        ) : (
          <></>
        )}

        <span className="text-lg" style={{ fontSize: 20 }}>
          <strong>{formatTimeData(currentTime)}</strong>
        </span>
        <br />
      </div>
    </div>
  );
}

Time.propTypes = {
  image: PropTypes.string,
  time: PropTypes.string,
  max: PropTypes.string,
  id: PropTypes.string,
  nom: PropTypes.string,
};

export default Time;
