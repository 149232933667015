import React from "react";
import SemiGaugeChart from "./GaugeChartCard/semi";
import ProgressBar from "./ProgressBar";
import Record from "./record";

function Stats() {
  return (
    <div className="w-100">
      <div className="theme-card w-100" style={{ marginBottom: 12 }}>
        <h2 className="text-center mb-5 title">Satisfaction client</h2>
        <SemiGaugeChart title={"Satisfaction client"} value={85.52} />
      </div>
      <div className="theme-card w-100" style={{ marginBottom: 12 }}>
        <div className="mb-3">
          <p className="mb-3">
            <strong>Résolution au premier appel</strong>
          </p>
          <ProgressBar value={89} />
        </div>
        <div className="mb-3">
          <p className="mb-3">
            <strong>Taux d’abandon moyen</strong>
          </p>
          <ProgressBar value={33} />
        </div>
        <div className="mb-3">
          <p className="mb-3">
            <strong>Temps d'attente moyen</strong>
          </p>
          <ProgressBar value={52} />
        </div>
      </div>
      <Record />
    </div>
  );
}

export default Stats;

const styles = {
  prime: {
    label: {
      fontWeight: 600,
      fontSize: 24,
    },
    value: {
      fontWeight: 700,
      fontSize: 36,
    },
    date: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
  acd: {
    label: {
      fontWeight: 600,
      fontSize: 16,
    },
    value: {
      fontWeight: 700,
      fontSize: 36,
    },
  }
}