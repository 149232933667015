const setColor = (value) => {
  if (value <= 30) {
    return "#D60000";
  }
  if (value <= 45) {
    return "#DE5607";
  }
  if (value <= 70) {
    return "#FFD000";
  }
  return "#1ECF02";
};

function chartConfig(value, styles) {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        startAngle: 360,
        radius: styles?.radius??["57%", "80%"],
        height: styles?.height??250,
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "center",
          color: setColor(value),
          fontSize: styles?.fontSize??"1.4rem",
          fontWeight: 700,
          formatter: function(){
            return value+"%";
          },
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: 100 - value,
            name: "Perdu",
            itemStyle: {
              color: "#0C292E",
            },
          },
          {
            value: value,
            name: `Décroché`,
            itemStyle: {
              color: setColor(value),
            },
          },
        ],
      },
    ],
  };
}

export { chartConfig };
