import { getSecond } from "./dates";

export const detailColor = (value) => {
  if (value <= 30) {
    return "#D60000";
  }
  if (value <= 45) {
    return "#DE5607";
  }
  if (value <= 70) {
    return "#FFD000";
  }
  return "#1ECF02";
};

export const formatAgents = (list, size = 16) => {
  const tab = [];
  for (let i = 0; i < list.length; i += size) {
    let t = [];
    for (let j = 0; j < size; j++) {
      if (list[i + j]) t.push(list[i + j]);
    }

    tab.push(t);
  }
  return tab;
};

export const getDating = (datas) => {
  return datas.filter((data) => data.queue === 900);
};

export const getAnnuaire = (datas) => {
  return datas.filter((data) => data.queue === 901);
};

export const getSos = (datas) => {
  return datas.filter((data) => data.queue === 903);
};

function compareAcd(a, b) {
  const a_value = getSecond(a.avg_duration2);
  const b_value = getSecond(b.avg_duration2);
  if (a_value < b_value) {
    return 1;
  }
  if (a_value > b_value) {
    return -1;
  }
  return 0;
}

export const bestAgent = (datas) => {
  return datas.sort(compareAcd).slice(0, 5);
};

export const lastAgent = (datas) => {
  return datas.sort(compareAcd).reverse().slice(0, 3);
};
