import React from "react";
import Status from "../Agent/status";

function Pause({ users }) {
  return (
    <div className="container" style={{ width: "70%" }}>
      <h2 style={styles.title} className="mb-5"><strong>Votre temps de pause est écoulé</strong></h2>
      <div className="d-flex flex-wrap" style={{ justifyContent: "space-between" }}>
        {users.map((user, index) => (
          <div key={index}>
            <Status
              nom={user.nom}
              active={user.active}
              image={user.image}
              imageSize={158}
              fontSize={32}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pause;

const styles = {
  title: {
    fontSize: 64,
  },
};
