import React from "react";
import { formatFullDate } from "../../helpers/dates";
import { useAlert } from "../../context/AlertContext";

import logo from "../../images/logo.png";

export default function Nav(props) {
  const [time, settime] = React.useState(null);
  const { state } = useAlert();
  const { open } = state;

  React.useEffect(() => {
    const handleTime = setInterval(() => {
      settime(new Date());
    }, 1000);

    return () => clearInterval(handleTime);
  }, []);

  return (
    <nav
      className={`d-flex align-items-center py-4 px-4 ${
        open ? "blur-page" : ""
      }`}
    >
      <div className="d-flex align-items-center g-2 me-auto">
        <img src={logo} alt="" height={50} className="me-3" />
        <h3 className="mb-0" style={styles.h3}>
          Tableau de bord
        </h3>
      </div>
      <div>
        <h1>{props.title}</h1>
      </div>
      <div className="ms-auto" style={styles.h3}>
        {formatFullDate(time)}
      </div>
    </nav>
  );
}

const styles = {
  h3: {
    fontSize: "32px",
  },
};
