import React from "react";
import BarChartCard from "../BarChartCard";
import Loading from "../Loading";
import Erreur from "../erreur";
import { filterCallData, formatData } from "../../../helpers/tendency";
import {
  getTendencyCallAnnuaire,
  getTendencyCallDating,
  getTendencyCallSos,
} from "../../../services/getServices";

function Card({ dates, data, title, isLoading, error }) {
  if (error) {
    return (
      <div className="py-5">
        <Erreur />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="py-5">
        <Loading />
      </div>
    );
  }

  return <BarChartCard title={title} data={formatData(data)} labels={dates} />;
}

export default function Calls({ dates }) {
  const [dating, setDating] = React.useState([]);
  const [annuaire, setAnnuaire] = React.useState([]);
  const [sos, setSos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const handleData = ({ service, setState }) =>
    service()
      .then((response) => {
        const { data } = response;
        if (data && data.length > 0) setState(data);
        else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });

  const handleDataDating = () =>
    handleData({ service: getTendencyCallDating, setState: setDating });

  const handleDataAnnuaire = () =>
    handleData({ service: getTendencyCallAnnuaire, setState: setAnnuaire });

  const handleDataSos = () =>
    handleData({ service: getTendencyCallSos, setState: setSos });

  const setData = React.useCallback(
    (key) => ({
      dating: filterCallData(dating.filter((item) => item.status === key)),
      annuaire: filterCallData(annuaire.filter((item) => item.status === key)),
      sos: filterCallData(sos.filter((item) => item.status === key)),
    }),
    [dating, annuaire, sos]
  );

  React.useEffect(() => {
    handleDataDating();
    handleDataAnnuaire();
    handleDataSos();
  }, []);

  React.useEffect(() => {
    if (dating.length > 0 && annuaire.length > 0 && sos.length > 0) {
      setLoading(false);
    }
  }, [dating, annuaire, sos]);

  return (
    <>
      <Card
        isLoading={loading}
        error={error}
        dates={dates}
        data={setData("Answered")}
        title={"Appels reçus"}
      />
      <Card
        isLoading={loading}
        error={error}
        dates={dates}
        data={setData("Call failed")}
        title={"Appels perdus"}
      />
    </>
  );
}
