import React from "react";
import {
  getAcdHighestPrime,
  getHighestPrime,
} from "../../services/getServices";
import { formatTimeData } from "../../helpers/dates";
import Loading from "./Loading";
import { numberWithSpaces } from "../../helpers/number";

const defaultState = {
  dating: "",
  annuaire: "",
  sos: "",
};

function Record() {
  const [state, setState] = React.useState(defaultState);
  const [prime, setPrime] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [loadingPrime, setLoadingPrime] = React.useState(false);
  const [erreur, setErreur] = React.useState(false);

  const getTime = (datas, q_num) => {
    const data = datas?.find((item) => item.q_num === q_num.toString());
    if (data && data.talk_time) {
      return formatTimeData(data.talk_time);
    }
    return "00:00";
  };

  const handleData = () => {
    getAcdHighestPrime()
      .then(({ data }) => {
        setState({
          annuaire: getTime(data, 901),
          dating: getTime(data, 900),
          sos: getTime(data, 903),
        });
      })
      .catch(() => {
        setErreur(true);
      })
      .finally(() => setLoading(false));
  };

  const handlePrime = () => {
    getHighestPrime()
      .then(({ data }) => {
        setPrime(data.prime);
      })
      .catch(() => {
        setErreur(true);
      })
      .finally(() => setLoadingPrime(false));
  };

  React.useEffect(() => {
    setLoading(true);
    handleData();
    setLoadingPrime(true);
    handlePrime();
    const handleTime = setInterval(() => {
      handleData();
      handlePrime();
    }, 60000);

    return () => clearInterval(handleTime);
  }, []);

  return (
    <div className="w-100 theme-card">
      <h2 className="title text-center">Performance Record</h2>
      {loadingPrime ? (
        <Loading />
      ) : (
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "space-between" }}
        >
          <span style={styles.prime.label}>Prime</span>
          <span style={styles.prime.value}>
            {numberWithSpaces(prime.toFixed(2))} Ar
          </span>
          <span style={styles.prime.date}>10/22</span>
        </div>
      )}

      <div className="line my-3"></div>
      {loading ? (
        <Loading />
      ) : (
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="me-3">
            <span style={styles.acd.label} className="d-block">
              Dating
            </span>
            <span style={styles.acd.value} className="d-block">
              {state.dating}
            </span>
          </div>
          <div className="me-3">
            <span style={styles.acd.label} className="d-block">
              Annuaire
            </span>
            <span style={styles.acd.value} className="d-block">
              {state.annuaire}
            </span>
          </div>
          <div className="me-3">
            <span style={styles.acd.label} className="d-block">
              Sos
            </span>
            <span style={styles.acd.value} className="d-block">
              {state.sos}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Record;

const styles = {
  prime: {
    label: {
      fontWeight: 600,
      fontSize: 24,
    },
    value: {
      fontWeight: 700,
      fontSize: 36,
    },
    date: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
  acd: {
    label: {
      fontWeight: 600,
      fontSize: 16,
    },
    value: {
      fontWeight: 700,
      fontSize: 36,
    },
  },
};
