import React from "react";
import PropTypes from "prop-types";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { chartConfig } from "./settings";

import "../../../scss/charts.scss";

function BarChartCard({ title, data, labels }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    CanvasRenderer,
  ]);

  return (
    <div className="py-1 chart-card d-flex flex-column align-items-center">
      <div className="w-100">
        <ReactEChartsCore
          echarts={echarts}
          option={chartConfig(title, labels, data)}
          notMerge={true}
          lazyUpdate={true}
          onChartReady={() => {}}
          style={{ height: 250 }}
        />
      </div>
    </div>
  );
}

BarChartCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  labels: PropTypes.array,
};

export default BarChartCard;
