import { detailColor } from "../../../helpers/acd";

function chartConfig(value) {
  return {
    series: [
      {
        type: "gauge",
        progress: {
          show: false,
          width: 12,
        },
        axisLine: {
          lineStyle: {
            width: 16,
            color: [
              [0.3, "#D60000"],
              [0.45, "#DE5607"],
              [0.7, "#FFD000"],
              [1, "#1ECF02"],
            ],
          },
        },
        axisTick: {
          show: true,
          distance: 0,
          lineStyle: {
            width: 1,
            color: "#fff9",
          },
        },
        splitLine: {
          length: 10,
          distance: 0,
          lineStyle: {
            width: 2,
            color: "#fff",
          },
        },
        axisLabel: {
          show: false,
          distance: 20,
          color: "#999",
          fontSize: 12,
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 25,
          color: "grey",
          itemStyle: {
            borderColor: "#D4D4D4",
            borderWidth: 3,
          },
        },
        pointer: {
          itemStyle: {
            color: "white",
          },
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          fontSize: 32,
          color: detailColor(value),
          offsetCenter: [0, "45%"],
          formatter: function (value) {
            return value + "%";
          },
        },
        data: [
          {
            value,
          },
        ],
      },
    ],
  };
}

export { chartConfig };
