import React from "react";
import PieChartCard from "../PieChartCard";
import Erreur from "../erreur";
import Loading from "../Loading";

function PieCard({ handleData, url, title, rightBar }) {
  const [data, setData] = React.useState(null);
  const [offhook, setOffhook] = React.useState(0);
  const [lost, setLost] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleDataDating = () =>
    handleData({ url, setState: setData });

  React.useEffect(() => {
    handleDataDating();
  }, []);

  React.useEffect(() => {
    if (data) {
      const answered = data.find((i) => i.status === "Answered");
      const cancelled = data.find((i) => i.status === "Cancelled");
      setLoading(false);
      if (answered) {
        setOffhook(parseInt(answered.number_of_calls));
      }
      if (cancelled) {
        setLost(parseInt(cancelled.number_of_calls));
      }
    }
  }, [data]);

  const getRate = () => (((offhook - lost) / offhook) * 100).toFixed(2);

  if (error) {
    return (
      <div className="py-5">
        <Erreur />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="py-5">
        <Loading />
      </div>
    );
  }

  return (
    <PieChartCard
      lost={lost}
      offhook={offhook}
      titre={title}
      value={getRate()}
      rightBar={rightBar}
    />
  );
}

export default PieCard;
