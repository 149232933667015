import React from "react";
import { Col, Row } from "react-bootstrap";
import Status from "./Agent/status";
import { formatAgents } from "../../helpers/acd";
import { Swiper, SwiperSlide } from "swiper/react";
import Loading from "./Loading";
import { maxLength } from "../../helpers/texts";

import { Autoplay } from "swiper";
import Response from "./response";
import { useAgent } from "../../context/AgentContext";

const Card = ({ width, title, loading, list, active }) => (
  <div
    className="theme-card d-flex flex-column"
    style={{ width, height: "70%", overflow: "hidden" }}
  >
    <h2 className="title">{title}</h2>
    {loading ? (
      <Loading />
    ) : (
      <Swiper
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        className="swiper-agent"
        modules={[Autoplay]}
      >
        {list.map((users, index) => (
          <SwiperSlide key={index}>
            <Row className="pt-3">
              {users.map((user, i) => (
                <Col md={3} key={i}>
                  <Status
                    nom={maxLength(user.display_name.split(" ")[0], 8)}
                    active={active}
                    image={
                      process.env.REACT_APP_AGENT_IMAGE_URL + user.photo_file
                    }
                    fontSize={14}
                  />
                </Col>
              ))}
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
    )}
  </div>
);

function Disponibility() {
  const [online, setOnline] = React.useState([]);
  const [offline, setOffline] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { state } = useAgent();

  const handleData = (agents) => {
    const online_agents = formatAgents(
      agents.filter((r) => r.stats && r.stats.length && !r.oncalls)
    );
    const offline_agents = formatAgents(
      agents.filter((r) => r.stats && r.stats.length === 0)
    );

    setOnline(online_agents);
    setOffline(offline_agents);
    setLoading(false);
  };

  React.useEffect(() => {
    if (state.agents && state.agents.length) {
      handleData(state.agents);
    }
  }, [state, state.agents]);

  return (
    <div className="d-flex flex-wrap flex-gap h-100">
      <Card
        width={"calc(55% - 6px)"}
        active={true}
        list={online}
        loading={loading}
        title={"Agents disponibles"}
      />
      <Card
        width={"calc(45% - 6px)"}
        active={false}
        list={offline}
        loading={loading}
        title={"Agents hors ligne"}
      />
      <Response />
    </div>
  );
}

export default Disponibility;
