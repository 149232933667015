import React from "react";
import { Col } from "react-bootstrap";
import { responseWait } from "../../services/getServices";
import Loading from "./Loading";
import { twoDigit } from "../../helpers/number";
import { getSecond } from "../../helpers/dates";

const defaultState = {
  dating: 0,
  annuaire: 0,
  sos: 0,
};

function Response() {
  const [state, setState] = React.useState(defaultState);
  const [loading, setLoading] = React.useState(false);
  const [erreur, setErreur] = React.useState(false);

  const getTime = (datas, name) => {
    const data = datas?.find(
      (item) => item.group_name.toUpperCase() === name.toUpperCase()
    );
    if (data && data.wating) {
      return twoDigit(getSecond(data.wating));
    }
    return "00";
  };

  const handleData = () => {
    setLoading(true);
    responseWait()
      .then(({ data }) => {
        setState({
          annuaire: getTime(data, "ANNUAIRE"),
          dating: getTime(data, "DATING"),
          sos: getTime(data, "SOS"),
        });
      })
      .catch(() => {
        setErreur(true);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    handleData();
    const handleTime = setInterval(() => {
      handleData();
    }, 60000);

    return () => clearInterval(handleTime);
  }, []);

  return (
    <div className="theme-card" style={{ width: "calc(100%)" }}>
      <h2 className="title">Temps de réponse max.</h2>
      {loading ? (
        <Loading />
      ) : (
        <div className="d-flex mt-4">
          <Col md={4}>
            <span style={styles.label}>Dating</span>
            <p style={styles.value}>{state.dating}s</p>
          </Col>
          <Col md={4}>
            <span style={styles.label}>Annuaire</span>
            <p style={styles.value}>{state.annuaire}s</p>
          </Col>
          <Col md={4}>
            <span style={styles.label}>SOS</span>
            <p style={styles.value}>{state.sos}s</p>
          </Col>
        </div>
      )}
    </div>
  );
}

export default Response;

const styles = {
  label: {
    fontSize: 24,
  },
  value: {
    fontSize: 64,
    fontWeight: "bold",
  },
};
