import React from "react";
import { Col, Row } from "react-bootstrap";
import PieCard from "./Appels/pieCard";
import { socket } from "../../services/socketServices";

function Appels() {
  const handleData = ({ url, setState, setError }) =>
    socket.on(url, (response) => {
      setState(response);
      if (
        !response ||
        !(response && response.length > 0 && response[0].number_of_calls)
      ) {
        setError(true);
      }
    });

  return (
    <div className="theme-card w-100" style={{ height: "100%", marginTop: 12 }}>
      <h2 className="text-center mb-3 title" style={{ fontSize: 32 }}>
        Nombre d’appels ce mois ci
      </h2>
      <Row>
        <Col md={4}>
          <PieCard
            title={"Dating"}
            handleData={handleData}
            url={"numberdatingcurrentmounth"}
            rightBar={true}
          />
        </Col>
        <Col md={4}>
          <PieCard
            title={"Annuaire"}
            handleData={handleData}
            url={"numberannuairecurrentmounth"}
            rightBar={true}
          />
        </Col>
        <Col md={4}>
          <PieCard
            title={"73204"}
            handleData={handleData}
            url={"number73204currentmounth"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Appels;
