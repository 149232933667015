import React from "react";
import Best from "./Agent/best";
import LightPieChart from "./PieChartCard/light";
import ACD, { Legend } from "./Performance/acd";
// import { socket } from "../../services/socketServices";
import { getBestAgent, getBestAgentInfos } from "../../services/getServices";
import { formatName } from "../../helpers/texts";
import Loading from "./Loading";
import { formatTimeData } from "../../helpers/dates";

function BestAgent() {
  const [state, setState] = React.useState(null);
  const [infos, setInfos] = React.useState(null);
  const [received, setReceived] = React.useState(0);

  const getData = (q_num) => {
    if (infos) {
      const data = infos.find((item) => item.q_num === q_num);
      if (data) {
        return formatTimeData(data.talk_time);
      }
    }
    return "00:00";
  };

  const handleData = (setState) => {
    getBestAgent().then((result) => {
      if (result.data && result.data[0]) {
        setState(result.data[0]);
      }
    });
  };

  const calls = (datas) => {
    let total = 0;
    for (const item of datas) {
      total += parseInt(item);
    }

    return total;
  };

  const handleInfos = () => {
    getBestAgentInfos().then((result) => {
      if (result.data && result.data[0]) {
        setReceived(calls(result.data.map((item) => item.number_of_calls)));
        setInfos(result.data);
      }
    });
  };

  React.useEffect(() => {
    handleData(setState);
    handleInfos();
    const handleTime = setInterval(() => {
      handleData(setState);
      handleInfos();
    }, 30000);

    return () => clearInterval(handleTime);
  }, []);

  if (!state && !infos) {
    return (
      <div className="theme-card pb-4 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <div className="theme-card pb-4 h-100">
      <h2 className="text-center mb-5 title">Meilleur Agent du mois</h2>
      {state ? (
        <Best
          image={process.env.REACT_APP_AGENT_IMAGE_URL + state.profile}
          nom={formatName({
            firstname: state.firstname,
            lastname: state.lastname,
          })}
          dating={getData("900")}
          annuaire={getData("901")}
          sos={getData("903")}
          lostCalls={received}
        />
      ) : (
        <div className="text-center">...</div>
      )}
      <div className="line my-4"></div>
      <div className="d-flex">
        <div style={styles.chart}>
          <LightPieChart titre={"Satisfaction client"} value={93.43} />
        </div>
        <div style={styles.chart}>
          <LightPieChart titre={"Dynamisme"} value={97.64} />
        </div>
        <div style={styles.chart}>
          <LightPieChart titre={"Ponctualité"} value={67.05} />
        </div>
      </div>
      <div className="line my-4"></div>
      <div className="w-100">
        <h3 className="title2">Tendance ACD</h3>
        <ACD />
        <Legend />
      </div>
    </div>
  );
}

export default BestAgent;

const styles = {
  chart: { flex: "0 0 calc(100% / 3)", width: "calc(100% / 3)" },
};
