import React, { useReducer } from "react";
import { socket } from "../services/socketServices";

const defaultContext = {
  state: null,
  dispatch: () => null,
};

const AgentContext = React.createContext(defaultContext);

const defaultState = {
  agents: [],
};

const reducer = (state, newState) => ({
  ...state,
  ...newState,
});

const AgentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const handleData = () => {
    socket.on("status_agent", (result) => {
      dispatch({
        agents : result,
      })
    });
  };

  React.useState(() => {
    handleData();
  }, []);

  return (
    <AgentContext.Provider value={{ state, dispatch }}>
      {children}
    </AgentContext.Provider>
  );
};

const useAgent = () => React.useContext(AgentContext);

export { AgentProvider, AgentContext, useAgent };
