import React from "react";
import PropTypes from "prop-types";

import "../../../scss/agent.scss";

const IMAGESIZE = 40;

const defaultImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
function ProfileLight({ image, nom }) {
  return (
    <div className="agent d-flex align-items-center">
      <div
        className={`img-profile me-3`}
        style={{
          width: IMAGESIZE,
          height: IMAGESIZE,
          flex: `0 0 ${IMAGESIZE}px`,
        }}
      >
        <img
          src={
            image === "https://3cx.ucorp.io/user_images/" ||
            image === "https://3cx.ucorp.io/user_images/null"
              ? defaultImage
              : image
          }
          alt=""
        />
      </div>
      <span>{nom}</span>
    </div>
  );
}

ProfileLight.propTypes = {
  image: PropTypes.string,
  nom: PropTypes.string,
};

export default ProfileLight;
