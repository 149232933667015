const twoDigit = (number) => {
  if (number < 10 && number >= 0) return "0" + number;
  if (number > -10 && number < 0) return "-0" + Math.abs(number);

  return number;
};

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export { twoDigit, numberWithSpaces };
