import React from 'react'

function Erreur() {
  return (
    <div className="theme-card w-100 py-5 d-flex justify-content-center text-danger display-6">
      Une erreur s'est produite lors de la récupération des données
    </div>
  )
}

export default Erreur