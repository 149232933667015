import React from 'react'
import Progress from '../components/progress'
import OnHold from '../components/onHold'

import "../../scss/appels.scss"
import Disponibility from '../components/disponibility'

function Appels() {
  return (
    <div className='d-flex px-4 flex-gap h-100 pb-4' id='appels'>
      <div style={{ width: "40%" }}>
        <Progress />
      </div>
      <div style={{ width: "15%" }}>
        <OnHold />
      </div>
      <div className='h-100' style={{ width: "45%" }}>
        <Disponibility />
      </div>
    </div>
  )
}

export default Appels