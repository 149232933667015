import React from "react";
import { useAlert } from "../../../context/AlertContext";

import danger_icon from '../../../images/alert.png'
import info_icon from '../../../images/info.png'

import "../../../scss/alert.scss";

function Alert() {
  const { state } = useAlert();
  const { variant, icon, title, children, open } = state;

  return (
    <div
      className={`d-flex alert-popup alert-${variant} w-100 ${
        open ? "d-block" : "d-none"
      }`}
    >
      <div
        className={`alert-title text-white d-flex flex-column justify-content-center p-5 pr-4`}
        style={{ alignItems: "flex-end" }}
      >
        <img src={
          icon === "danger" ? danger_icon : info_icon
        } alt="" className="alert-icon" />
        <span className="title mt-3" style={{ lineHeight: 1 }}>{title}</span>
      </div>
      <div className="p-5 w-100 alert-content">{children}</div>
    </div>
  );
}

export default Alert;