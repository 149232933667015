import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AlertContextProvider from "./context/AlertContext";
import Kpi from "./jsx/pages/kpi";
import Performance from "./jsx/pages/performance";
import Culture from "./jsx/pages/culture";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";

import "./App.css";

function App() {
  return (
    <AlertContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Kpi />} />
          <Route path="/performance-agent" element={<Performance />} />
          <Route path="/culturelle-france" element={<Culture />} />
        </Routes>
      </BrowserRouter>
    </AlertContextProvider>
  );
}

export default App;
