import React from "react";
import PropTypes from "prop-types";
import { detailColor } from "../../../helpers/acd";

function ProgressBar({ value, max = 100, min = 0, unit = "%" }) {
  const v = (100 * value) / max;

  return (
    <div className="acd-progress-bar w-100">
      <div className="progress-container">
        <div
          className="bar-value"
          style={{ backgroundColor: detailColor(v), width: `${v}%` }}
        ></div>
      </div>
      <div className="bar-line" style={{ left: `${v}%` }}></div>
      <div className="labels">
        <span className="min">{min}</span>
        <span className="value" style={{ left: `${v}%` }}>
          {value + "" + unit}
        </span>
        <span className="max">{max}</span>
      </div>
    </div>
  );
}

export default ProgressBar;

ProgressBar.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  unit: PropTypes.string,
};
