import { dL_fr, mL_fr } from "../constants/dates";
import { twoDigit } from "./number";

export function formatFullDate(dateString) {
  const d = new Date(dateString);

  return `${dL_fr[d.getDay()]}, ${twoDigit(d.getDate())} ${
    mL_fr[d.getMonth()]
  } ${d.getFullYear()}, ${twoDigit(d.getHours())}:${twoDigit(d.getMinutes())}`;
}

export function formatLDate(date) {
  const d = new Date(date);

  return `${dL_fr[d.getDay()]} ${twoDigit(d.getDate())} ${
    mL_fr[d.getMonth()]
  } ${d.getFullYear()}`;
}

export const lastMonths = (months = 6, twoDigitYear = false) => {
  const tab = [];
  let today = new Date();

  for (var i = months - 1; i > -1; i -= 1) {
    let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    tab.push(formatDate(d, twoDigitYear));
  }

  return tab;
};

export const formatDate = (date, twoDigitYear = false) => {
  const d = new Date(date);
  return `${twoDigit(d.getMonth() + 1)}/${
    twoDigitYear ? d.getFullYear().toString().slice(-2) : d.getFullYear()
  }`;
};

export const formatTimeData = (time) => {
  const timeSplit = time.split(":");
  if (timeSplit.length > 1) {
    return `${timeSplit[timeSplit.length - 2]}:${
      timeSplit[timeSplit.length - 1]
    }`;
  }
  return time;
};

export const getRate = (current, max) => {
  const currentSplit = current.split(":");
  const maxSplit = max.split(":");

  if (currentSplit.length === 2 && maxSplit.length === 2) {
    const currentSec =
      parseInt(currentSplit[0]) * 60 + parseInt(currentSplit[1]);
    const maxSec = parseInt(maxSplit[0]) * 60 + parseInt(maxSplit[1]);
    let rate = Math.round((currentSec * 100) / maxSec);

    if (rate > 100) rate = 100;

    // return Math.round(100 - Math.abs((currentSec - maxSec) / maxSec) * 100);
    return rate;
  }

  return 0;
};

export const getSecond = (time) => {
  const timeSplit = time.split(":");
  if (timeSplit.length > 2) {
    var seconds = (timeSplit[0] * 60 * 60) + (parseInt(timeSplit[1]) * 60) + parseInt(timeSplit[2]);
    return seconds;
  }
  if (timeSplit.length > 1) {
    var seconds = (timeSplit[0] * 60) + parseInt(timeSplit[1]);
    return seconds;
  }
  return 0;
};

export const formatMinute = (second) => {
  const min = Math.floor(second / 60);
  return `${twoDigit(min)}:${twoDigit(second % 60)}`;
};

export const getTimeDifference = (time) => {
  const d_1 = new Date(),
    d_2 = new Date(time);
  const difference = d_1.getTime() - d_2.getTime();
  return {
    seconds: Math.floor(difference / 1000) % 60,
    minutes: Math.floor(difference / (1000 * 60)) % 60,
    hours: Math.floor(difference / (1000 * 60 * 60)) % 24,
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  };
};

export const addSecond = (time) => {
  let s = getSecond(time);
  s = s + 1;
  return formatMinute(s);
};
