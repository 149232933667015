import React from "react";
import GaugeChartCard from "./GaugeChartCard";
import { formatTimeData, getRate } from "../../helpers/dates";
import Erreur from "./erreur";
import { acd_ojective as max } from "../../constants/acd";
import Ripple from "./Loading/ripple";
import { socket } from "../../services/socketServices";

function Acd() {
  const [dating, setDating] = React.useState(null);
  const [annuaire, setAnnuaire] = React.useState(null);
  const [sos, setSos] = React.useState(null);
  const [erreur, setErreur] = React.useState(false);

  const handleData = ({ url, setState }) => {
    
    socket.on(url, (result) => {
      if (Array.isArray(result) && result.length > 0) {
        setState(result[0].avg_duration2);
      }
    });
  };
  

  const handleDataDating = () =>
    handleData({ url: "AcdGeneralDating", setState: setDating });

  const handleDataAnnuaire = () =>
    handleData({ url: "AcdGeneralAnnuaire", setState: setAnnuaire });

  const handleDataSos = () =>
    handleData({ url: "AcdGeneral73204", setState: setSos });
  
   
   
  
    

  React.useEffect(() => {
    handleDataDating();
    handleDataAnnuaire();
    handleDataSos();
   
  }, []);
  

  return !erreur ? (
    <div className="d-flex" style={{ gap: "12px" }}>
      <div style={{ width: `${100 / 3}%` }}>
        {dating ? (
          <GaugeChartCard
            title={"ACD Dating"}
            value={getRate(formatTimeData(dating), max.dating)}
            objective={max.dating}
            time={formatTimeData(dating)}
          />
        ) : (
          <Ripple />
        )}
      </div>
      <div style={{ width: `${100 / 3}%` }}>
        {annuaire ? (
          <GaugeChartCard
            title={"ACD Annuaire"}
            value={getRate(formatTimeData(annuaire), max.annuaire)}
            objective={max.annuaire}
            time={formatTimeData(annuaire)}
          />
        ) : (
        <Ripple />
        )}
      </div>
      <div style={{ width: `${100 / 3}%` }}>
        {sos ? (
          <GaugeChartCard
            title={"ACD 73204"}
            value={getRate(formatTimeData(sos), max.sos)}
            objective={max.sos}
            time={formatTimeData(sos)}
          />
        ) : (
          <Ripple />
        )}
      </div>
    </div>
  ) : (
    <Erreur />
  );
}

export default Acd;
